<template>
  <div class="container__home">
    <div class="container__login">
      <h1>Inscription</h1>
      <form class=" user form" @submit.prevent="onSubmit">
        <label class="user__label" for="name">Pseudo</label>
        <input
          class="user__input"
          type="text"
          name="pseudo"
          id="pseudo"
          v-model="pseudo"
        />
        <label class="user__label" for="email">Adresse Mail</label>
        <input
          class="user__input"
          type="email"
          name="email"
          id="email"
          v-model="email"
        />
        <label class="user__label" for="password">Password</label>
        <input
          class="user__input"
          type="password"
          name="password"
          id="password"
          v-model="password"
        />
        <label class="user__label" for="passwordConfirmation"
          >Password Confirmation</label
        >
        <input
          class="user__input"
          type="password"
          name="passwordConfirmation"
          id="passwordConfirmation"
          v-model="confirmPassword"
        />
        <button class=" user__button button" type="submit">
          Inscription
        </button>
      </form>
      <router-link to="/login" class="user__register">
        <span>Se connecter</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      pseudo: "",
      errors: []
    };
  },
  methods: {
    onSubmit() {
      this.errors = [];

      if (!this.pseudo) {
        this.errors.push("Name required");
      }

      if (!this.email) {
        this.errors.push("Valid mail required");
      }

      // Password => 1 Uppercase, 1 lowercase, 1 numeric digit and between 6 and 20 characters
      const regPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

      if (!this.password.match(regPassword)) {
        this.errors.push("Password weak");
      }

      if (this.password !== this.confirmPassword) {
        this.errors.push("Not same password");
      }

      if (this.errors.length > 0) return;

      const data = {
        pseudo: this.pseudo,
        email: this.email,
        password: this.password
      };

      this.$store.dispatch("signup", data);
    }
  }
};
</script>

<style lang="scss">
h1 {
  font-size: 40px;
  color: #23233c;
  z-index: 10;
}
.form {
  display: inline-flex;
  flex-direction: column;
}

.button {
  margin-top: 20px;
  padding: 20px 20px;
  border: 1px solid red;
  cursor: pointer;
}

.container {
  &__home {
    background-image: url("../assets/background.png");
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
      content: " ";
      background-image: url("../assets/header_home.svg");
      background-size: contain;
      z-index: 0;
      height: 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
      width: 100%;
      top: -10px;
    }

    &::after {
      content: " ";
      background-image: url("../assets/footer_home.svg");
      background-size: contain;
      z-index: 0;
      height: 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
      width: 100%;
      bottom: -10px;
      background-position: bottom;
    }
  }

  &__login {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: auto;
    justify-content: center;
  }
}
.user {
  display: flex;
  flex-direction: column;
  margin: 40px;
  margin-bottom: 0;
  z-index: 1;

  &__input {
    padding: 20px;
    border: none;
    background-color: #fff;
    box-shadow: 0px 10px 10px rgba(13, 78, 129, 0.051);
    border-radius: 5px;
  }

  &__label {
    display: flex;
    flex-direction: revert;
    font-size: 16px;
    color: #3b5150;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 500;
  }
  &__button {
    color: #fff;
    padding: 15px 30px;
    background-color: #95cdcb;
    border: none;
    font-weight: 600;
    font-size: 15px;
    display: block;
    margin: 20px auto;
    border-radius: 18px;
    text-transform: uppercase;
  }
  &__register {
    color: #898888;
    z-index: 10;
  }
}
@media (min-width: 850px) {
  .container {
    &__home {
      background-image: url("../assets/background_desktop.svg");
      &::before {
        background-image: url("../assets/login/header_desktop.svg");
      }
      &::after {
        background-image: url("../assets/login/footer_desktop.svg");
      }
    }
  }
  .user {
    display: block;
  }
  .form {
    display: flex;
    width: 30%;
    margin: 0 auto;
  }
}
</style>
